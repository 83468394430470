import React from "react"
import Component from "./component"
import fixtures from "./fixtures"

const Jumbotron = (props) => {
  return <Component {...props} {...fixtures.default} />
}

export default Jumbotron

export { Component as component, fixtures }
