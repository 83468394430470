import React from "react"

import { animateScroll } from "react-scroll"
import SVG from "@src/components/svg"

import styles from "./styles.module.scss"

const scrollMore = () => {
  animateScroll.scrollMore(800)
}

const ScrollerButton = () => {
  return (
    <button className={styles.scroller_button} onClick={scrollMore}>
      <span className="screen-reader-only">Scroll down</span>
      <SVG name="arrow-down" />
    </button>
  )
}

export default ScrollerButton
