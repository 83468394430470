import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Lottie from "react-lottie-player"

import styles from "./styles.module.scss"

const SectionNumber = ({ number }) => {
  const [animationData, setAnimationData] = useState()

  useEffect(() => {
    import(`./animations/${number}-circle-animation`).then(setAnimationData)
  }, [])

  return (
    <Lottie className={styles.section_number} animationData={animationData} play loop={false} />
  )
}

SectionNumber.propTypes = {
  number: PropTypes.string
}

SectionNumber.defaultProps = {}

export default SectionNumber
