import React, { Fragment } from "react"

export default {
  default: {
    heading: (
      <Fragment>
        <span>YOU Want</span> <span>ADVENTURE?</span> <span>YOU GOT IT.</span> – GOD
      </Fragment>
    ),
    subheading: (
      <Fragment>
        <span>Okay, okay…so maybe it wasn’t the</span> <span>adventure we planned.</span>
      </Fragment>
    )
  }
}
