/* eslint-disable react/prop-types */

import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import GatsbyBackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"

const StaticBackgroundImage = ({
  children,
  className,
  fadeIn,
  image: filePath,
  onLoad,
  onStartLoad,
  style
}) => {
  const data = useStaticQuery(
    graphql`
      query {
        backgroundImage: allFile(filter: { ext: { in: [".jpg", ".png"] } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxWidth: 1400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  )

  let image = data.backgroundImage.edges.filter((node) => node.node.relativePath === filePath)

  if (!image || !image[0]) {
    return null
  }

  image = image[0].node

  return (
    <GatsbyBackgroundImage
      className={classNames(`static-background-image`, className, {})}
      fadeIn={fadeIn}
      fluid={image.childImageSharp.fluid}
      onLoad={onLoad}
      onStartLoad={onStartLoad}
      style={style}
    >
      {children}
    </GatsbyBackgroundImage>
  )
}

StaticBackgroundImage.propTypes = {
  /**
   * Specifies anything between the opening and closing tag.
   */
  children: PropTypes.node,
  /**
   * Additional classes on the wrapping element (the one with the background
   * image).
   */
  className: PropTypes.string,
  /**
   * Accepts the image's relative path
   */
  image: PropTypes.string,
  onLoad: PropTypes.func,
  onStartLoad: PropTypes.func
}

StaticBackgroundImage.defaultProps = {
  fadeIn: "soft"
}

export default StaticBackgroundImage
