import React, { useState } from "react"
import loadable from "@loadable/component"

import Jumbotron from "./components/jumbotron"
const Started = loadable(() => import(/* webpackPrefetch: true */ "./components/started"))
const CovidCallout = loadable(() =>
  import(/* webpackPrefetch: true */ "./components/covid-callout")
)
const NoPanic = loadable(() => import(/* webpackPrefetch: true */ "./components/no-panic"))

const AdventurousLife = () => {
  const [revealed, setRevealed] = useState(false)

  return (
    <section>
      <Jumbotron onReveal={() => setRevealed(true)} />
      <Started revealed={revealed} />
      <CovidCallout />
      <NoPanic />
    </section>
  )
}

export default AdventurousLife
