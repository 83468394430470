import React, { useState } from "react"

import PropTypes from "prop-types"
import classNames from "classnames"

// import FontFaceObserver from "fontfaceobserver"
import TrackVisibility from "react-on-screen"

import BackgroundImage from "@src/components/background-image/static"
import SectionNumber from "@src/components/section-number"

import styles from "./styles.module.scss"

const Jumbotron = ({ heading, subheading, onReveal }) => {
  const [backgroundImageLoaded, setBackgroundImage] = useState(false)

  // ------------------------------------------------------ | Check if fonts are loaded

  const acuminProFontIsLoaded = true
  const sentinelFontIsLoaded = true

  const ready = acuminProFontIsLoaded && sentinelFontIsLoaded && backgroundImageLoaded

  return (
    <div className={styles.jumbotron_background}>
      <BackgroundImage
        className={styles.jumbotron}
        image="adventurous-life/01--header--bg@2x.jpg"
        style={{ backgroundPosition: "top right" }}
        onLoad={() => {
          setBackgroundImage(true)
          onReveal()
        }}
      >
        <div className={styles.jumbotron_container}>
          <div className={styles.content}>
            <TrackVisibility partialVisibility once>
              {({ isVisible }) => (
                <div
                  className={classNames(styles.section_number, `animate__animated is-hidden`, {
                    [[`animate__fadeIn`]]: ready && isVisible
                  })}
                >
                  <SectionNumber number="01" />
                </div>
              )}
            </TrackVisibility>

            <TrackVisibility partialVisibility once>
              {({ isVisible }) => (
                <div
                  className={classNames(styles.heading, `animate__animated is-hidden`, {
                    [[`fadeInUp`]]: ready && isVisible
                  })}
                >
                  <h1>{heading}</h1>
                </div>
              )}
            </TrackVisibility>

            <TrackVisibility partialVisibility once>
              {({ isVisible }) => (
                <div
                  className={classNames(styles.subheading, `reveal-from-left reveal-orange`, {
                    [[`is-showing transition-delay-5`]]: ready && isVisible
                  })}
                >
                  <h2>{subheading}</h2>
                </div>
              )}
            </TrackVisibility>
          </div>
        </div>
      </BackgroundImage>
    </div>
  )
}

Jumbotron.propTypes = {
  backgroundImage: PropTypes.object,
  heading: PropTypes.node,
  subheading: PropTypes.node,
  isVisible: PropTypes.bool,
  onReveal: PropTypes.func.isRequired
}

Jumbotron.defaultProps = {}

export default Jumbotron
